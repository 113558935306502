<template>
    <div class="my">
        <div class="my_top">
            <img width="100" src="@/assets/index/logo.png" alt="">
            
        </div>
        <div class="my_gongNeng">
            <div class="my_gongNeng_div">
                <div>
                    <van-icon name="setting-o" />设置
                </div>
                <div>
                    <van-icon name="arrow" />
                </div>
            </div>
            
            <div class="my_gongNeng_div">
                <div>
                    <van-icon name="friends-o" />入会申请
                </div>
                <div>
                    <van-icon name="arrow" />
                </div>
            </div>
            
            <div class="my_gongNeng_div">
                <div>
                    <van-icon name="orders-o" />关于我们
                </div>
                <div>
                    <van-icon name="arrow" />
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import {
  defineComponent,reactive
} from "@/plugin/importCommonUse";
import { Dialog } from 'vant';
import { useRouter } from "vue-router";
import moduleHead from "@/components/moduleHead/index.vue"
export default defineComponent({
    components:{
        moduleHead
    },
    setup() {
        let router = useRouter();
        let skip = (url)=>{
            router.push(url);
        }
        // let dataSuier = ()=>{
        //     Dialog({ 
        //         title: '温馨提示',
        //         message: '该页面正在努力开发中，敬请等待！',
        //     }).then(() => {
        //         router.go(-1);
        //     });
        // }
        // dataSuier();
        return {
            skip
        };
    },
})
</script>
<style>
.initiationApply{
    width: 100%;
    /* border-bottom: 1px solid #dcdee2; */
}
.my_top{
    background: #B44238;
    width: 100%;
    height: 180px;
    display: flex;
    justify-content:center;
    align-items:center;
    
}
.my_top div {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background: #ffff;
    display: flex;
    justify-content:center;
    align-items:center;
    color: rgb(228, 228, 228);
}
.my_gongNeng{
    margin: 10px 0px;
}
.my_gongNeng_div{
    height: 40px;
    background: rgba(243, 243, 243, 0.8);
    margin: 10px 0;
    display: flex;
    justify-content:space-between;
    align-items:center;
    padding:  0 10px;
    font-size: 16px;
}
.my_gongNeng_div:active{
    background: rgba(236, 236, 236, 0.8);
}
.my_gongNeng_div div{
    display: flex;
    justify-content:flex-start;
    align-items:center;
}
.my_gongNeng div i{
    margin: 3px 5px 0 0;
    font-size: 20px;
}
</style>
